import React from "react";
import ChatApp from "./components/ChatApp";
//import Login from "./components/Login";
//import PdfReader from "./components/PdfReader";


function App() {
  return (
    <div className="App">
      <h1>Read Smarter</h1>
      <ChatApp />
    </div>
  );
}

export default App;
