import React, { useState } from "react";
import { speak } from "../utils/tts";
import questionsData from "../data/questions.json";

import "./ChatApp.css";
import { FaPaperPlane } from "react-icons/fa"; // Icon for the Send button

function ChatApp() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const generateResponse = (input) => {
    console.log("User input for matching:", input); // Debugging log
  
    // Match input with questions.json
    const match = questionsData.find((q) =>
      input.toLowerCase().includes(q.question.toLowerCase())
    );
  
    console.log("Match found:", match); // Debugging log
  
    if (match && match.answers.length > 0) {
      // Combine all answers into one paragraph
      return match.answers.join(" ");
    }
  
    return "I'm not sure I understand. Can you clarify?"; // Default fallback
  };
  
  
  
  const handleSend = () => {
    if (!input.trim()) {
      console.log("Empty input, ignoring."); // Debugging log
      return;
    }
  
    const userMessage = { sender: "user", text: input };
    const botMessage = { sender: "bot", text: generateResponse(input) };
  
    console.log("User message:", userMessage); // Debugging log
    console.log("Bot message:", botMessage);   // Debugging log
  
    setMessages((prevMessages) => [...prevMessages, userMessage, botMessage]);
    setInput(""); // Clear input field
  
    // Read bot message aloud
    speak(botMessage.text, 0.8);
  };
  

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h2>
            Sennai
        </h2>
      </div>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`chat-message ${
              msg.sender === "user" ? "user-message" : "bot-message"
            }`}
          >
            <div className="message-text">{msg.text}</div>
          </div>
        ))}
      </div>
      <div className="chat-input-container">
        <input
          type="text"
          className="chat-input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your question..."
        />
        <button className="send-button" onClick={handleSend}>
          <FaPaperPlane />
        </button>
      </div>
    </div>
  );
}

export default ChatApp;
